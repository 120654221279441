import { useNavigate } from "react-router-dom";

import { getAnalytics, logEvent } from "firebase/analytics";

const LoginSession = () => {
  const analytics = getAnalytics();
  const navigate = useNavigate();

  const logoutBtn = async () => {
    logEvent(analytics, "logout_router_button", {
      content_type: "RouterButton",
      item_id: "logout",
    });
    navigate("/");
  };

  return (
    <div className="w-full mx-auto flex justify-end">
      <button
        onClick={logoutBtn}
        className="rounded-lg mt-2 pt-2 pb-1 pr-4 pl-4 bg-blue-500 text-white hover:bg-blue-600 shadow-md transition-all duration-200"
      >
        초기화면으로
      </button>
    </div>
  );
};

export default LoginSession;
