import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { ErrorOutline } from "@mui/icons-material";
import CertificationContainer from "./CertificationContainer";
import SubmitSummary from "./SubmitSummary";
import { handleSaveToSheet } from "../api/postGoogleSheet";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../api/userContext";
import { getAnalytics, logEvent } from "firebase/analytics";
import { postEmailSend } from "../api/postEmailSendApi";
import ResultTemplate from "../template/ResultTemplate";

const UserInputContext = () => {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const location = useLocation();
  const userResultInfo = location.state || {};
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [emailDomain, setEmailDomain] = useState("@gmail.com");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const analytics = getAnalytics();
  const [, setIsLoading] = useState(false);
  const [isPhoneValid] = useState(true);
  const emailOptions = ["@gmail.com", "@naver.com", "@daum.net", "@kakao.com", "직접 입력"];

  // 유효성 검사
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^010-\d{4}-\d{4}$/;
    return phoneRegex.test(number);
  };

  // 결과 창으로 보내는 navigate
  const handleSubmitAndResult = () => {
    if (!name || !validatePhoneNumber(phone)) {
      alert("성함과 유효한 핸드폰 번호를 입력해주세요.");
      return;
    }

    // 이메일 도메인 처리
    let completedEmail = email;
    if (emailDomain !== "직접 입력") {
      completedEmail = email + emailDomain;
    }

    setIsLoading(true);
    setUserInfo({ name, phone, completedEmail });

    // 데이터 처리 완료 후 navigate
    logEvent(analytics, "google_sheet_router_button", {
      content_type: "RouterButton",
      item_id: "google_sheet",
    });
    setTimeout(() => {
      setIsLoading(false);
      navigate("/result");
    }, 500);
  };

  // userInfo에 입력된 값 저장 후 google sheet로 전달
  useEffect(() => {
    if (!userInfo) return;

    const emailContent = ResultTemplate({
      name: userInfo.name,
      phone: userInfo.phone,
      email: userInfo.completedEmail,
      totalScore: userResultInfo.totalScore,
      status: userResultInfo.status,
      finalImage: userResultInfo.finalImage,
      imageRecommendation: userResultInfo.imageRecommendation,
      recommendation: userResultInfo.recommendation,
      products: userResultInfo.products,
    });

    const sendData = async () => {
      if (userInfo) {
        // 사용자 정보를 구글 시트로 전송
        await handleSaveToSheet(userInfo);
        // 설문조사 회원 정보 메일로 전송
        if (userInfo.completedEmail) {
          await postEmailSend({
            emailContent,
            email: userInfo.completedEmail,
          });
        }
      }
      setUserInfo(null);
    };

    sendData();
  }, [userInfo]);

  // 개인정보 처리방침 modal 열고 닫는 상태 변경
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 이메일 다시 입력
  const resetEmail = () => {
    setEmailDomain("@gmail.com");
  };

  return (
    <div>
      <CertificationContainer />
      <div className="w-full max-w-md p-7 mx-auto bg-white rounded-lg shadow-xl">
        <h2 className="mb-4 text-2xl font-bold text-gray-800 text-center">결과를 알고 싶다면 정보를 입력해주세요</h2>

        {/* Name Input */}
        <div className="mb-3">
          <div className="flex">
            <label className="block text-gray-700 text-lg font-semibold mb-2">성함 </label>
            <label className="block text-red-700 text-lg font-semibold mb-2">*</label>
          </div>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="이름을 입력하세요"
            className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
          />
        </div>

        {/* Phone Number Input */}
        <div className="mb-3">
          <div className="flex">
            <label className="block text-gray-700 text-lg font-semibold mb-2">핸드폰 번호</label>
            <label className="block text-red-700 text-lg font-semibold mb-2">*</label>
          </div>
          <input
            type="text"
            value={phone}
            onChange={(e) => {
              const rawValue = e.target.value.replace(/[^0-9]/g, ""); // 숫자 이외의 문자 제거
              let formattedPhone = "";

              if (rawValue.length <= 3) {
                formattedPhone = rawValue; // 3자리 이하일 때
              } else if (rawValue.length <= 7) {
                formattedPhone = `${rawValue.slice(0, 3)}-${rawValue.slice(3)}`; // 3-4 형태
              } else {
                formattedPhone = `${rawValue.slice(0, 3)}-${rawValue.slice(3, 7)}-${rawValue.slice(7, 11)}`; // 3-4-4 형태
              }

              setPhone(formattedPhone); // 상태 업데이트
            }}
            placeholder="핸드폰 번호를 입력하세요"
            className={`w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none ${
              isPhoneValid ? "border-gray-300" : "border-red-500"
            }`}
          />
        </div>

        {/* Email Input */}
        <div>
          <label className="block text-gray-700 text-lg font-semibold mb-2">이메일 입력</label>
          <div className="block">
            {emailDomain === "직접 입력" ? (
              <div className="flex items-center justify-between space-x-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@example.com"
                  className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
                <button
                  className="px-4 py-3 whitespace-nowrap bg-purple-500 text-white rounded-lg shadow hover:bg-purple-600 transition duration-300"
                  onClick={resetEmail}
                >
                  돌아가기
                </button>
              </div>
            ) : (
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="이메일 ID"
                  className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
                <select
                  className="w-40 px-3 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  onChange={(e) => setEmailDomain(e.target.value)}
                >
                  {emailOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-7 text-center">
          <button
            onClick={handleSubmitAndResult}
            className={
              "w-full px-6 py-3 rounded-lg shadow text-white bg-purple-500 hover:bg-purple-600 transition duration-300"
            }
          >
            상담 신청 하기
          </button>
        </div>
      </div>

      {/* 개인정보 처리 방침 버튼 */}
      <div className="text-center mt-4 text-blue-800 flex items-center justify-center relative" onClick={openModal}>
        <ErrorOutline
          className="text-blue-800 mr-2 cursor-pointer hover:text-blue-600 focus:text-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
          style={{ fontSize: "20px", verticalAlign: "middle" }}
        />
        <div className="mt-1 cursor-pointer hover:text-blue-600 focus:text-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded">
          개인 정보 처리 동의서
        </div>
      </div>

      {/* 모달 컴포넌트 */}
      <SubmitSummary isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default UserInputContext;
