// userInfo에 대한 정보도 가져와서 해당 템플릿으로 작성하여 사용할것
const ResultTemplate = ({
  name,
  phone,
  email,
  totalScore,
  status,
  finalImage, // 이미지 URL
  imageRecommendation, // 이미지에 대한 설명
  recommendation,
  products,
}) => {
  const surveyTime = new Date().toLocaleString();

  return `
    <div style="max-width: 600px; background: #fff; padding: 25px; border-radius: 12px; 
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); line-height: 1.6; font-family: Arial, sans-serif;">
      <h2 style="color: #333; text-align: center">📝 노후 준비 결과</h2>
      <div style="background: #f9f9f9; padding: 15px; border-radius: 8px; margin-bottom: 15px;">
        <p><strong>이름: ${name || "없음"}</strong></p>
        <p><strong>핸드폰: ${phone || "없음"}</strong></p>
        <p><strong>이메일: ${email || "없음"}</strong></p>
        <p><strong>총점: ${totalScore} / 80</strong> </p>
        <p><strong>노후 준비 상태: ${status}</strong> </p>
      </div>
      <h3 style="color:rgb(255, 106, 0); margin-top: 20px;">📌 결과 설명</h3>
      <div style="background: #fff8e1; padding: 15px; border-left: 5px solid #ff9800; border-radius: 8px; margin-bottom: 20px;">
      ${recommendation
        .split(".")
        .filter(Boolean)
        .map((sentence) => `<p style="margin: 5px 0;">${sentence.trim()}.</p>`)
        .join("")}
      </div>

      <h3 style="color: #007bff; margin-top: 20px;">💡 추천 상품 Top 3</h3>
      <ul style="list-style: none; padding: 0;">
        ${products
          .map(
            (product, index) => `<li style="background: #e3f2fd; margin: 5px 0; padding: 10px; 
                        border-radius: 6px; display: flex; align-items: center;">
                          <span style="margin-left: 10px;">${index + 1}. ${product.name}</span>
                        </li>`
          )
          .join("")}
      </ul>
      <hr style="margin-top: 20px;"/>
      <p style="color: #555; text-align: center;">📩 성공적인 투자 성공을 기원합니다.</p>
      <p style="text-align: center;"><strong>설문 조사 시간: ${surveyTime}</strong> </p>
      
    </div>
  `;
};

export default ResultTemplate;
