// 사용자에게 결과지를 보내는 내역 보내는 API
export const postEmailSend = async (emailContent) => {
  const API_URL = process.env.REACT_APP_BACKEND_API_SERVER_URI + "/send-email/moneyterview";

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailContent),
    });
    if (!response.ok) {
      throw new Error(`Network response was not ok, status code: ${response.status}`);
    }
    const data = await response.json();
    return data; // 성공적으로 이메일이 전송되었을 때 반환
  } catch (error) {
    console.error("Failed to send email:", error);
    return { success: false, error: error.message };
  }
};
